import '/assets/styles/sections/footer.scss';
import config from '/config';
import { Fragment, useState, useMemo } from 'react'
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Context
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals'
import { useGlobalState } from "@wearetla/tla-essentials-tools/utilities/global-state";
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';

// Partials
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Btn from '/views/partials/btn'
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Placeholder from '/views/partials/placeholder'
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

// Hooks
import useVisible from '@wearetla/tla-essentials-tools/hooks/visible';

// Data
import etbisHTML from '/data/etbis-html'
import popularLinks from '/data/popular-links'

// Static Assets
import image_app_btn_apple from '/assets/images/footer/btn-app-apple.svg';
import image_app_btn_google from '/assets/images/footer/btn-app-google.svg';
// import image_logo_tla from '/assets/images/logo-tla.svg';
// Payment
import image_footer_payment_secure from '/assets/images/footer/payment/secure.svg';
import image_footer_payment_mastercard from '/assets/images/footer/payment/mastercard.svg';
import image_footer_payment_visa from '/assets/images/footer/payment/visa.svg';
import image_footer_payment_amex from '/assets/images/footer/payment/amex.svg';
import image_footer_payment_troy from '/assets/images/footer/payment/troy.svg';
import image_footer_payment_3dsecure from '/assets/images/footer/payment/3dsecure.svg';
import image_footer_payment_mcsecurecode from '/assets/images/footer/payment/mcsecurecode.svg';
import image_footer_payment_verifiedbyvisa from '/assets/images/footer/payment/verifiedbyvisa.svg';
import image_footer_payment_axess from '/assets/images/footer/payment/axess.svg';
import image_footer_payment_maximum from '/assets/images/footer/payment/maximum.svg';
import image_footer_payment_bonuscard from '/assets/images/footer/payment/bonuscard.svg';
import image_footer_payment_worldcard from '/assets/images/footer/payment/worldcard.svg';
import image_footer_payment_advantage from '/assets/images/footer/payment/advantage.svg';
import image_footer_payment_cardfinans from '/assets/images/footer/payment/cardfinans.svg';
import image_footer_payment_paraf from '/assets/images/footer/payment/paraf.svg';


const Footer = ({ plain }) => {
	const { openModal } = useModals();
	const { faqs: rawFaqs, featuredCategories: rawFeaturedCategories, footerProducts } = useGlobalState();
	const { mobile } = useBreakpoints();
	
	// const [popularBrands, setPopularBrands] = useState([false, false, false, false, false, false, false]);
	
	const faqs = useMemo(() => (rawFaqs || [false, false, false, false, false, false, false, false, false, false]), [rawFaqs]);
	const featuredCategories = useMemo(() => (rawFeaturedCategories || [false, false, false, false, false, false, false, false, false, false]), [rawFeaturedCategories])

	// useEffect(() => {
	// 	siteServices.getFooterBrands().then((brands) => {
	// 		setPopularBrands(brands);
	// 	}).catch(() => {
	// 		setPopularBrands(false);
	// 	})
	// }, [])

	const CardsWrap = mobile ? 'div' : Fragment;

	return (
		<footer className={classNames('section', 'footer', { 'plain': plain })}>
			<div className="footer-main">
				<div className="main-wrap wrapper">
					{!plain &&
						<>
							<nav className="main-menu">
								<div className="menu-col">
									<MenuSection mobile={mobile} title="Popüler Kategoriler">
										<div className="list-row">
											<>
												{featuredCategories.map((category, nth) => (
													<div className="row-col" key={nth}>
														{category ?
															<Link
																href="category"
																params={{ slug: category.slug, id: category.id }}
																className="col-link">
																{category.name}
															</Link>
															:
															<Placeholder
																dark
																className="col-link" />
														}
													</div>
												))}
											</>
										</div>
									</MenuSection>
								</div>
								<div className="menu-col">
									{(footerProducts ?? []).map(productGroup => (
										<MenuSection key={productGroup.id} title={productGroup.title}>
											<div className="list-row">
												{productGroup.products.map((product => (
													<div className="row-col" key={product.id}>
														<Link
															href="productDetail"
															params={{ id: product.id, slug: product.slug }}
															className="col-link">
															{product.title}
														</Link>
													</div>
												)))}
											</div>
										</MenuSection>
									))}
								</div>
								<div className="menu-col">
									<StaticMenuSection data={popularLinks.brands} />
								</div>
								<div className="menu-col">
									<MenuSection mobile={mobile} title="Kurumsal">
										<div className="list-row">
											<div className="row-col">
												<Link
													href="about"
													rel="nofollow"
													className="col-link">
													Hakkımızda
												</Link>
											</div>
											<div className="row-col">
												<Link
													href="contact"
													rel="nofollow"
													className="col-link">
													İletişim Bilgileri
												</Link>
											</div>
											<div className="row-col">
												<a
													href="https://www.belgemodul.com/sirket/1569"
													rel="noopener noreferrer nofollow"
													target="_blank" className="col-link">
													Bilgi Toplum Hizmetleri
												</a>
											</div>
											<div className="row-col">
												<Link
													href="agreement"
													rel="nofollow"
													params={{ type: 'kisisel-verilerin-korunmasi' }}
													className="col-link">
													KVKK Aydınlatma Metni
												</Link>
											</div>
											<div className="row-col">
												<Link
													href="agreement"
													rel="nofollow"
													params={{ type: 'kvkk-acik-riza-metni' }}
													className="col-link">
													KVKK Açık Rıza Metni
												</Link>
											</div>
											<div className="row-col">
												<Link
													href="cookies"
													rel="nofollow"
													className="col-link">
													Çerez Politikası
												</Link>
											</div>
											<div className="row-col">
												<Link
													href="agreement"
													rel="nofollow"
													params={{ type: 'uyelik-sozlesmesi' }}
													className="col-link">
													Üyelik Sözleşmesi
												</Link>
											</div>
											<div className="row-col">
												<Link
													href="agreement"
													rel="nofollow"
													params={{ type: 'gizlilik-politikasi' }}
													className="col-link">
													Gizlilik ve Güvenlik Politikası
												</Link>
											</div>
											<div className="row-col">
												<Link
													href="agreement"
													rel="nofollow"
													params={{ type: 'ticari-ileti-izni' }}
													className="col-link">
													Ticari İleti İzni
												</Link>
											</div>
											<div className="row-col">
												<Link
													href="agreement"
													rel="nofollow"
													params={{ type: 'garanti-ve-iade-sartlari' }}
													className="col-link">
													Garanti ve İade Şartları
												</Link>
											</div>
											<div className="row-col">
												<Link
													target="_blank"
													raw
													href="https://bebegimicinhersey.com/"
													rel="noreferrer nofollow"
													className="col-link">
													Bebeğim İçin Her Şey Blog
												</Link>
											</div>
											<div className="row-col">
												<Link
													target="_blank"
													raw
													href="https://www.ozelcan.com/Kariyer/Ilanlar"
													rel="noreferrer nofollow"
													className="col-link">
													Kariyer
												</Link>
											</div>
											<div className="row-col">
												<Link
													href="announcements"
													rel="nofollow"
													className="col-link">
													Duyurular
												</Link>
											</div>
										</div>
									</MenuSection>
									<MenuSection mobile={mobile} title="Müşteri Hizmetleri & Yardım">
										<div className="list-row">
											{faqs &&
												<>
													{faqs.map((faq, nth) => (
														<div className="row-col" key={nth}>
															{faq ?
																<Link
																	href="faqDetail"
																	rel="nofollow"
																	params={{ slug: faq.slug, id: faq.id }} className="col-link">
																	{faq.name}
																</Link>
																:
																<Placeholder 
																	dark
																	className="col-link" />
															}
														</div>
													))}
												</>
											}
										</div>
									</MenuSection>
								</div>
								<div className="menu-col">
									<MenuSection mobile={mobile} title="Hesabım">
										<div className="list-row">
											<div className="row-col">
												<Link
													href="account.orders"
													rel="nofollow"
													className="col-link">
													Siparişlerim
												</Link>
											</div>
											{config.enableLoyalty &&
												<div className="row-col">
													<Link
														href="account.loyaltyInfo"
														rel="nofollow"
														className="col-link">
														Kalbim Kart Programı
													</Link>
												</div>
											}
											<div className="row-col">
												<Link
													href="account.children"
													rel="nofollow"
													className="col-link">
													Bebek Bilgilerim
												</Link>
											</div>
											<div className="row-col">
												<Link
													href="account.info"
													rel="nofollow"
													className="col-link">
													Üyelik Bilgilerim
												</Link>
											</div>
											<div className="row-col">
												<Link
													href="account.addresses"
													rel="nofollow"
													className="col-link">
													Adres Bilgilerim
												</Link>
											</div>
											<div className="row-col">
												<Link
													href="account.messages"
													rel="nofollow"
													className="col-link">
													Mesajlarım
												</Link>
											</div>
											{/* <div className="row-col">
												<Link
													href="account.giftCodes"
													rel="nofollow"
													className="col-link">
													Hediye Çeklerim
												</Link>
											</div> */}
											<div className="row-col">
												<Link
													href="account.alarms"
													rel="nofollow"
													className="col-link">
													Alarmlarım
												</Link>
											</div>
											<div className="row-col">
												<Link
													href="account.favorites"
													rel="nofollow"
													className="col-link">
													Favorilerim
												</Link>
											</div>
										</div>
									</MenuSection>
								</div>
								<div className="menu-col">
									<div className="menu-section social">
										<strong className="section-title">Bizi Takip Edin</strong>
										<div className="section-list">
											<div className="list-row">
												{config.social.facebook &&
													<div className="row-col">
														<a
															href={`https://www.facebook.com/${config.social.facebook}`}
															title="Facebook"
															rel="noopener noreferrer nofollow"
															target="_blank"
															className="col-link">
															<Icon name="facebook" />
															<span>Facebook</span>
														</a>
													</div>
												}
												{config.social.instagram &&
													<div className="row-col">
														<a
															href={`https://instagram.com/${config.social.instagram}`}
															title="Instagram"
															rel="noopener noreferrer nofollow"
															target="_blank"
															className="col-link">
															<Icon name="instagram" />
															<span>Instagram</span>
														</a>
													</div>
												}
												{config.social.x &&
													<div className="row-col">
														<a
															href={`https://x.com/${config.social.x}`}
															title="X"
															rel="noopener noreferrer nofollow"
															target="_blank"
															className="col-link">
															<Icon name="x" />
															<span>X</span>
														</a>
													</div>
												}
												{config.social.youtube &&
													<div className="row-col">
														<a
															href={`https://youtube.com/user/${config.social.youtube}`}
															title="YouTube"
															rel="noopener noreferrer"
															target="_blank"
															className="col-link">
															<Icon name="youtube" />
															<span>YouTube</span>
														</a>
													</div>
												}
											</div>
										</div>
									</div>
								</div>
							</nav>
							<div className="main-mid">
								<div className="mid-section left">
									<strong className="mid-sidetitle">Bize Ulaşın</strong>
									<Btn
										tag="a"
										href={config.callCenterPhoneHref}
										className="pink"
										icon="callcenter">
										{config.callCenterPhone}
									</Btn>
									<Btn
										onClick={() => {
											openModal('message', { contact: true });
										}}
										className="pink"
										icon="form">
										iletişim formu
									</Btn>
									<Btn
										className={mobile ? 'nude' : 'pink'}
										tag="a"
										href="mailto:destek@babymall.com.tr"
										icon="envelope">
										destek@babymall.com.tr
									</Btn>
								</div>

								<div className="mid-section right">
									<Btn
										className={classNames('pink', { block: mobile })}
										tag="link"
										href="stores"
										icon="store">
										Mağazalarımız
									</Btn>
								</div>
							</div>
						</>
					}
					<div className="main-bottom">
						<div className="bottom-section app">
							<strong className="bottom-title">Mobil Uygulamayı İndirin</strong>
							<Btn
								data-gtm-id="downloadIosApp"
								tag="a"
								target="_blank"
								rel="noopener noreferrer"
								href="https://apps.apple.com/tr/app/babymall/id1569963871?l=tr" className="footer-app-btn rounded black">
								<Img src={image_app_btn_apple} />
							</Btn>
							<Btn
								data-gtm-id="downloadAndroidApp"
								tag="a"
								target="_blank"
								rel="noopener noreferrer"
								href="https://play.google.com/store/apps/details?id=com.positive.babymall" className="footer-app-btn rounded black">
								<Img src={image_app_btn_google} />
							</Btn>
							{/* <div className="bottom-qr">
								<div className="qr-codewrap">
									<Img src={image_app_qr} />
								</div>
								<strong>indirmek için <br />QR kodu okutun</strong>
							</div> */}
						</div>
						<div className="bottom-section etbis">
							<div id="ETBIS">
								<div id="00E9A734F6484F7FA79456125CF54940">
									<a
										href="https://etbis.eticaret.gov.tr/sitedogrulama/00E9A734F6484F7FA79456125CF54940"
										target="_blank"
										rel="noopener noreferrer"
										dangerouslySetInnerHTML={{ __html: etbisHTML}}>
									</a>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div className="wrapper">
				<div className="footer-payment">
					<strong>Güvenli Alışveriş</strong>
					<Img lazy className="payment-logo" alt="Güvenli Ödeme Logo" src={image_footer_payment_secure} />
					<Img lazy className="payment-logo" alt="Mastercard Logo" src={image_footer_payment_mastercard} />
					<Img lazy className="payment-logo" alt="Visa Logo" src={image_footer_payment_visa} />
					<Img lazy className="payment-logo" alt="American Express Logo" src={image_footer_payment_amex} />
					<Img lazy className="payment-logo breakpoint" alt="Troy Logo" src={image_footer_payment_troy} />
					<Img lazy className="payment-logo" alt="3D Secure Logo" src={image_footer_payment_3dsecure} />
					<Img lazy className="payment-logo" alt="Mastercard SecureCode Logo" src={image_footer_payment_mcsecurecode} />
					<Img lazy className="payment-logo" alt="Verified by Visa Logo" src={image_footer_payment_verifiedbyvisa} />
					<CardsWrap>
						<Img lazy className="payment-logo" alt="Axess Logo" src={image_footer_payment_axess} />
						<Img lazy className="payment-logo" alt="Maximum Logo" src={image_footer_payment_maximum} />
						<Img lazy className="payment-logo" alt="Bonus Card Logo" src={image_footer_payment_bonuscard} />
						<Img lazy className="payment-logo" alt="World Card Logo" src={image_footer_payment_worldcard} />
						<Img lazy className="payment-logo" alt="Advantage Logo" src={image_footer_payment_advantage} />
						<Img lazy className="payment-logo" alt="Card Finans Logo" src={image_footer_payment_cardfinans} />
						<Img lazy className="payment-logo" alt="Paraf Logo" src={image_footer_payment_paraf} />
					</CardsWrap>
				</div>
				<div className="footer-copyright">
					<span className="copyright-text">2021 Babymall | Tüm Hakları Saklıdır</span>
					{/* <a
						href="https://wearetla.com/"
						target="_blank"
						rel="noopener noreferrer"
						className="copyright-pstv">
						design & omni-commerce platform <Img alt="TLA Logo" lazy src={image_logo_tla} />
					</a> */}
				</div>
			</div>
		</footer>
	)
}

Footer.propTypes = {
	plain: PropTypes.bool,
}

const StaticMenuSection = ({ data }) => {
	return (
		<MenuSection title={data.title}>
			<div className="list-row">
				{data.products?.map((link, nth) => (
					<div className="row-col" key={nth}>
						<Link
							raw
							href={link.href}
							className="col-link">
							{link.title}
						</Link>
					</div>
				))}
				{data.links?.map((link, nth) => (
					<div className="row-col" key={nth}>
						<Link
							raw
							href={link.href}
							className="col-link">
							{link.title}
						</Link>
					</div>
				))}
			</div>
		</MenuSection>
	)
}

StaticMenuSection.propTypes = {
	data: PropTypes.object,
}

const MenuSection = ({ children, title }) => {
	const { mobile } = useBreakpoints();

	const [show, setShow] = useState(false);

	const [active, visible] = useVisible(show, 250);
	
	return (
		<div className={`menu-section${active ? ' active' : ''}${visible ? ' show' : ''}`}>
			{mobile ?
				<button
					type="button"
					onClick={() => { setShow(!show); }}
					className="section-btn">
					{title}
				</button>
				:
				<strong className="section-title">{title}</strong>
			}
			<div className="section-list">
				{children}
			</div>
		</div>
	)
}

MenuSection.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
}

export default Footer;
